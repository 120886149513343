export enum MiteActionTypes {
  SetChoosedPolicy = 'MITE/SET_CHOOSED_POLICY',
  SetPromoCode = 'MITE/SET_PROMO_CODE',
  SetInsuranceProductPolicy = 'MITE/SET_INSURANCE_PRODUCT_POLICY',
  SetInsurancePrice = 'MITE/SET_INSURANCE_PRICE',
  SetInsuredPersons = 'MITE/SET_INSURED_PERSON',
  SetNumberInsuredPersons = 'MITE/SET_NUMBER_INSURED_PERSON',
  SetInsuranceProductStartDate = 'MITE/SET_INSURANCE_PRODUCT_START_DATE',
  UpdateState = 'MITE/UPDATE_STATE',
}
