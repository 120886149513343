import { memo, useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import {
  PAYMENT_ERROR_ROUTE,
  PAYMENT_IN_PROGRESS_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
} from '@src/constants';
import { useRequest } from '@src/hooks';
import {
  AuthActionTypes,
  AuthFlowActionTypes,
  Store,
  UserActionTypes,
} from '@src/store';
import { refreshToken } from '@src/utils';

export const RefreshBox: FC = memo(() => {
  const {
    state: {
      stateAuth: { authTokens },
      stateUser: { profile },
      stateAuthFlow: { initAuthState },
    },
    dispatch,
  } = useContext(Store);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const agentLogin = searchParams.get('agent_login');
  const [accessToken, setAccessToken] = useState<string>();

  const { refetch: logout } = useRequest(
    'userLogout',
    'post',
    '/v1/user/logout',
    {},
    [accessToken],
    true,
    accessToken
  );

  useEffect(() => {
    if (!!agentLogin?.length && !!accessToken?.length) {
      logout();
    }
  }, [accessToken, agentLogin]);

  useEffect(() => {
    {
      if (
        !!localStorage.getItem('csrfToken') &&
        localStorage.getItem('csrfToken') !== null &&
        !authTokens?.authorization?.csrfToken &&
        pathname !== PAYMENT_ERROR_ROUTE &&
        pathname !== PAYMENT_SUCCESS_ROUTE &&
        pathname !== PAYMENT_IN_PROGRESS_ROUTE
      ) {
        refreshToken()
          .then((e) => {
            setAccessToken(e?.data?.authorization?.accessToken);

            const {
              data: {
                authorization: { csrfToken, name, phone, userId },
              },
            } = e;
            localStorage.removeItem('csrfToken');

            if (!!agentLogin?.length) {
              return;
            }

            if (csrfToken) {
              localStorage.setItem('csrfToken', csrfToken);
            }
            dispatch({
              type: AuthActionTypes.SetAuthTokens,
              payload: e?.data,
            });
            dispatch({
              type: AuthActionTypes.SetPhoneNumber,
              payload: (phone as string) || '',
            });
            return dispatch({
              type: UserActionTypes.SetProfile,
              payload: {
                ...profile,
                profile: {
                  ...profile?.profile,
                  firstName: name || null,
                  userId: userId || null,
                  phone: phone || null,
                },
              },
            });
          })
          .then(() => {
            if (initAuthState) {
              dispatch({
                type: AuthFlowActionTypes.SetInitAuthState,
                payload: false,
              });
            }
          })
          .catch((error) => {
            const { response } = error;

            if (response?.status === 401) {
              localStorage.removeItem('csrfToken');
            }
            if (initAuthState) {
              dispatch({
                type: AuthFlowActionTypes.SetInitAuthState,
                payload: false,
              });
            }
            return null;
          });
      } else {
        if (initAuthState) {
          dispatch({
            type: AuthFlowActionTypes.SetInitAuthState,
            payload: false,
          });
        }
      }
    }
  }, []);

  return null;
});
