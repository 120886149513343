import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';

import { useHandlePressKey } from '@src/hooks';
import { KeyCode } from '@src/types';

export const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useHandlePressKey(KeyCode.ENTER, () => navigate(-1));

  return (
    <Button
      variant="primary"
      onClick={() => {
        navigate(-1);
      }}
      label={t('COMMON:buttons.understandably') || ''}
    />
  );
};
