/* eslint-disable indent */
import type { FC } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { assignRef, useCallbackRef } from 'use-callback-ref';

import { PageBackButton } from '@src/common-components/button';
import { ArrowLeftIcon2, Skeleton } from '@src/components';
import { nonSubscriptionProducts } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useClearWizardStore, useIsDesktop, useRequest } from '@src/hooks';
import { useClearStoreProducts } from '@src/hooks/use-clear-store-products';
import { Store, UserActionTypes } from '@src/store';
import { cleartDraft, handleAnalyticsEvents } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import { StyledIframe } from './best-to-pay-page.styles';

type ConfirmPaymentType = {
  subscriptionId: string;
  paymentDocumentId: string;
  operationId: string;
  id: string;
  contractId: string;
  amount: string;
  consents: {
    consentType: string;
  }[];
  paymentGateOrder: string;
};

export const BestToPayStartPage: FC = () => {
  const {
    state: {
      stateOrder: {
        orderInitData,
        orderSubmitData,
        completePaymentData,
        hasSpecialOffersConsent,
      },
      stateAuth: { authTokens },
      stateUser: { selectedProduct },
    },
    dispatch,
  } = useContext(Store);
  const { t } = useTranslation();
  const clearWizarsStore = useClearWizardStore();
  const clearStoreProducts = useClearStoreProducts();
  const isDesktop = useIsDesktop();
  const [isRetry, setRetry] = useState('');
  const [isShowBackButton, setIsShowBackButton] = useState(true);
  const refCount = useRef<number>(0);

  const preset = localStorage.getItem('preset');

  const onRefRetrydUpdate = (newValue: any) => {
    if (newValue && newValue !== null && refCount.current === 0) {
      refCount.current = 1;
      setRetry(String(newValue));
    }
  };

  const onRefSOperationIdUpdate = (newValue: any) => {
    if (newValue && newValue !== null) {
      completPayment.operationId = newValue;

      if (!!completPayment.id) {
        refetch();
      }
    }
  };

  const refRetry = useCallbackRef<null | string>(null, onRefRetrydUpdate);

  const refSOperationId = useCallbackRef<null | string>(
    null,
    onRefSOperationIdUpdate
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (!orderInitData) {
      navigate('/');
    }
  }, [orderInitData]);

  const storageListener = () => {
    if (
      localStorage.getItem('operationId') !== null &&
      refSOperationId.current !== localStorage.getItem('operationId')
    ) {
      cleartDraft();
      assignRef(refSOperationId, String(localStorage.getItem('operationId')));
      setIsShowBackButton(false);
    }

    if (
      localStorage.getItem('retryPayment') !== null &&
      refRetry.current !== String(localStorage.getItem('retryPayment'))
    ) {
      assignRef(refRetry, String(localStorage.getItem('retryPayment')));

      handleAnalyticsEvents.handleRetryPaymentAnalyticsEvents({
        selectedProduct,
        preset,
      });
    }

    if (localStorage.getItem('paymentDecline') !== null) {
      setIsShowBackButton(false);
    }

    if (localStorage.getItem('paymentResult') === 'success') {
      cleartDraft();
      clearStoreProducts();
    }

    if (localStorage.getItem('paymentResult') !== null) {
      navigate('/');
      clearWizarsStore();
      localStorage.removeItem('selectedProduct');

      dispatch({
        type: UserActionTypes.SetSelectedProduct,
        payload: undefined,
      });

      dispatch({
        type: UserActionTypes.SetCachedProduct,
        payload: undefined,
      });
    }
  };

  window.addEventListener('storage', storageListener);

  const sector = `sector=${orderInitData?.sector}`;
  const id = `id=${orderInitData?.id}`;
  const signature = `signature=${orderInitData?.signature}`;
  const url = `url=${orderInitData?.url}`;
  const failUrl = `failUrl=${orderInitData?.failUrl}`;
  const get_token = 1;

  const completPayment: ConfirmPaymentType = {
    subscriptionId:
      completePaymentData?.subscriptionId ||
      orderSubmitData?.subscriptionId ||
      '',
    paymentDocumentId:
      completePaymentData?.billId || orderSubmitData?.billId || '',
    operationId: '',
    id: orderInitData?.id || '',
    contractId:
      completePaymentData?.contractId || orderSubmitData?.contractId || '',
    amount:
      String(
        completePaymentData?.premiumAndDelta || orderSubmitData?.premiumAndDelta
      ) || '',
    paymentGateOrder: orderInitData?.paymentGateOrder || '',
    consents: [
      ...(hasSpecialOffersConsent
        ? [
            {
              consentType: '002',
            },
          ]
        : []),
      ...(nonSubscriptionProducts.includes(selectedProduct || '')
        ? []
        : [
            {
              consentType: '001',
            },
          ]),
    ],
  };

  const { isLoading, error, res, refetch } = useRequest(
    'orderCompletePayment',
    'post',
    '/v1/payment/complete-payment',
    completPayment,
    [completPayment],
    true,
    authTokens?.authorization?.accessToken
  );

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!isLoading && res) {
      assignRef(refSOperationId, undefined);
      localStorage.removeItem('referenceSuccess');
      localStorage.removeItem('operationId');
      localStorage.removeItem('paymentDecline');
      localStorage.removeItem('paymentResult');
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (isRetry !== '') {
      setRetry('');
      setIsShowBackButton(true);
      refCount.current = 0;
      localStorage.removeItem('retryPayment');
    }
  }, [isRetry]);

  useEffect(() => {
    return () => {
      window.removeEventListener('storage', storageListener);

      localStorage.removeItem('paymentResult');
    };
  }, []);

  // TODO: доработать на этапе создания страницы выбора карты оплаты
  // const token = undefined;
  // const action = undefined;

  const propsString = [sector, id, signature, url, failUrl, get_token].join(
    '&'
  );

  const route = `Purchase?${propsString}`;

  if (error) return <GlobalErrorInfo retrayHandler={refetch} />;

  return (
    <BaseLayout>
      {isShowBackButton && (
        <PageBackButton
          variant="text"
          icon={<ArrowLeftIcon2 />}
          onClick={goBack}
        >
          {t('COMMON:buttons.back')}
        </PageBackButton>
      )}
      {!isDesktop && isLoading && <Skeleton />}
      {isRetry === '' ? (
        <StyledIframe
          src={`${window.envUrls.BEST2PAY_API_URL}/${route}`}
          allow="payment"
          sandbox="allow-top-navigation allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
        />
      ) : null}
    </BaseLayout>
  );
};
