import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';

const sendEvent = (event: string, presetEvent: string, isPreset: boolean) => {
  isPreset ? sendAnalyticEvent(presetEvent) : sendAnalyticEvent(event);
};

export const handleSignupComplete = (product: Product, isPreset: boolean) => {
  const signupCompleteHandler = {
    [Product.APARTMENT]: () => {
      sendAnalyticEvent(analyticEvents.authFlatSignupCompleted);
    },
    [Product.HOUSE]: () => {
      sendEvent(
        analyticEvents.authHouseSignupCompleted,
        analyticEvents.authHouseSignupCompletedPreset,
        isPreset
      );
    },
    [Product.NS]: () => {
      sendAnalyticEvent(analyticEvents.authNSSignupCompleted);
    },
    [Product.PETS]: () => {
      sendEvent(
        analyticEvents.authPetSignupCompleted,
        analyticEvents.authPetSignupCompletedPreset,
        isPreset
      );
    },
    [Product.SPORT]: () => {
      sendAnalyticEvent(analyticEvents.authSportSignupCompleted);
    },
    [Product.MITE]: () => {
      sendAnalyticEvent(analyticEvents.authMiteSignupCompleted);
    },
  };

  signupCompleteHandler[product]();
};
