/* eslint-disable indent */
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';

import { PromoSubmitButton } from '@src/common-components/button';
import {
  PromoTextInputWrapper,
  PromoWrapper,
} from '@src/common-components/container';
import { useHandlePressKey } from '@src/hooks';
import { formInsurancePeriodSchema } from '@src/schemas';
import { Store, UserActionTypes, WizardActionTypes } from '@src/store';
import { FormPromoProps, KeyCode } from '@src/types';
import { getPercentageDiff } from '@src/utils';

interface Props {
  promoCode?: string;
  isSuccessfulPromo?: boolean;
  premiumAndDelta?: string;
  premiumAndDeltaPromo?: string;
  isLoading?: boolean;
  isPricesLoading: boolean;
  onChange?: (promoCode?: string) => void;
  onSubmit: (promoCode?: string) => void;
}

export const PromoCode = ({
  promoCode,
  isSuccessfulPromo,
  premiumAndDelta,
  premiumAndDeltaPromo,
  isLoading,
  isPricesLoading,
  onChange,
  onSubmit,
}: Props) => {
  const {
    dispatch,
    state: {
      stateUser: { promoCodeFailedMessage },
      stateAuthFlow: { authStep },
    },
  } = useContext(Store);
  const { t } = useTranslation(['COMMON']);
  const [submitPromoDisabled, setSubmitPromoDisabled] = useState(false);
  const [isInputActive, setIsInputActive] = useState(false);
  const checkSubmitState = (val: string) => {
    setSubmitPromoDisabled(val === promoCode);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
    watch,
    getValues,
  } = useForm<FormPromoProps>({
    resolver: yupResolver(formInsurancePeriodSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: { promoCode },
  });

  useEffect(() => {
    const { promoCode: promoCodeValue } = getValues();
    if (promoCodeValue?.length === 0 && !isSuccessfulPromo) {
      dispatch({
        type: UserActionTypes.SetPromoCodeFailedMessage,
        payload: undefined,
      });
      onSubmit(undefined);
    }
  }, [watch('promoCode')]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (onChange) {
        onChange(value.promoCode ? value.promoCode : '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const submitPromoCode = handleSubmit((data) => {
    dispatch({
      type: UserActionTypes.SetPromoCodeFailedMessage,
      payload: undefined,
    });
    onSubmit(!!data.promoCode?.length ? data.promoCode : undefined);
  });

  const handleKeyPressEnter = () => {
    if (isInputActive) {
      submitPromoCode();
    } else if (authStep) {
      return;
    } else {
      dispatch({
        type: WizardActionTypes.UpdateWantNextStep,
        payload: true,
      });
    }
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, [isInputActive]);

  const getLabel = () => {
    const { promoCode: statePromoCode } = getValues();

    return isSuccessfulPromo && promoCode === statePromoCode
      ? t('COMMON:success.applied')
      : t('COMMON:success.apply');
  };

  useEffect(() => {
    setSubmitPromoDisabled(!!isSuccessfulPromo && promoCode !== '');
  }, [isSuccessfulPromo, promoCode]);

  useEffect(() => {
    if (!isPricesLoading && isSuccessfulPromo === false && !!promoCode) {
      setError('promoCode', {
        type: 'string',
        message: t('COMMON:errors.promoCodeNotValid') || '',
      });
      dispatch({
        type: UserActionTypes.SetPromoCodeFailedMessage,
        payload: t('COMMON:errors.promoCodeNotValid') || '',
      });
      setSubmitPromoDisabled(true);
    }
    if (!isPricesLoading && isSuccessfulPromo && !!promoCode) {
      clearErrors('promoCode');
    }
  }, [isPricesLoading, isSuccessfulPromo, promoCode]);

  useEffect(() => {
    if (!promoCode?.length) {
      clearErrors('promoCode');
    }
  }, [promoCode]);

  return (
    <PromoWrapper>
      <PromoTextInputWrapper>
        <Controller
          control={control}
          name="promoCode"
          render={({ field: { onChange, value }, fieldState }) => (
            <HelperText
              status={
                fieldState.error ||
                (promoCodeFailedMessage && !isSuccessfulPromo)
                  ? 'error'
                  : 'success'
              }
              message={
                errors.promoCode?.message ||
                (!isSuccessfulPromo && promoCodeFailedMessage) ||
                (isSuccessfulPromo && promoCode !== ''
                  ? t('messages.discount', {
                      discount:
                        premiumAndDelta &&
                        premiumAndDeltaPromo &&
                        getPercentageDiff(
                          premiumAndDelta,
                          premiumAndDeltaPromo
                        ),
                    })
                  : '')
              }
            >
              <Input
                label={t('labels.promoCode') || ''}
                value={value || ''}
                onChange={(val) => {
                  checkSubmitState(val);
                  onChange(val);
                }}
                onFocus={() => setIsInputActive(true)}
                onBlur={() => setIsInputActive(false)}
                error={
                  !!errors.promoCode ||
                  (!!promoCodeFailedMessage && !isSuccessfulPromo)
                }
                disabled={isLoading}
              />
            </HelperText>
          )}
        />
      </PromoTextInputWrapper>
      <PromoSubmitButton
        label={getLabel()}
        onClick={submitPromoCode}
        disabled={submitPromoDisabled}
        variant="secondary-2"
        adaptiveWidth
      />
    </PromoWrapper>
  );
};
