import { sendAnalyticEvent } from '@src/components/web-analytic';
import { Product, analyticEvents } from '@src/constants';

const sendEvent = (event: string, presetEvent: string, isPreset: boolean) => {
  isPreset ? sendAnalyticEvent(presetEvent) : sendAnalyticEvent(event);
};

export const handleRegistrationComplete = (
  product: Product,
  isPreset: boolean
) => {
  const registrationCompleteHandler = {
    [Product.APARTMENT]: () => {
      sendAnalyticEvent(analyticEvents.authFlatRegistrationCompleted);
    },
    [Product.HOUSE]: () => {
      sendEvent(
        analyticEvents.authHouseRegistrationCompleted,
        analyticEvents.authHouseRegistrationCompletedPreset,
        isPreset
      );
    },
    [Product.NS]: () => {
      sendAnalyticEvent(analyticEvents.authNSRegistrationCompleted);
    },
    [Product.PETS]: () => {
      sendEvent(
        analyticEvents.authPetRegistrationCompleted,
        analyticEvents.authPetRegistrationCompletedPreset,
        isPreset
      );
    },
    [Product.SPORT]: () => {
      sendAnalyticEvent(analyticEvents.authSportRegistrationCompleted);
    },
    [Product.MITE]: () => {
      sendAnalyticEvent(analyticEvents.authMiteRegistrationCompleted);
    },
  };

  registrationCompleteHandler[product]();
};
