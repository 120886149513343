import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormLabel } from '@src/components';
import { FeatureFlags } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';

interface Props {
  featureFlag: FeatureFlags;
  marginBottom?: number;
}

export const PaymentPeriodsTitle = ({ featureFlag, marginBottom }: Props) => {
  const { t } = useTranslation();
  const {
    res: [isFeatureSubscriptionType],
    isFeatureFlagsLoading,
  } = useFeatureFlags([featureFlag]);

  if (isFeatureFlagsLoading) {
    return null;
  }

  return (
    <FormLabel marginBottom={marginBottom}>
      {isFeatureSubscriptionType
        ? t('COMMON:headers.chooseSubscriptionType')
        : t('COMMON:headers.paymentOfPeriod')}
    </FormLabel>
  );
};
