/* eslint-disable indent */
import type { FC } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { Button } from '@pulse-web-ui/button';

import {
  Container,
  FormLabel,
  FormSub,
  FormSubLabel,
  GridColumnContainer,
} from '@src/components';
import { sendUserIdToAnalytics } from '@src/components/web-analytic/utils';
import { useIsDesktop } from '@src/hooks';
import { handleAnalyticsEvents } from '@src/utils';

import { BaseLayout } from '../../layouts/base-layout';
import { useHandleGoBack } from './hooks';

export const BestToPayErrorPage: FC = () => {
  const { t } = useTranslation();
  useHandleGoBack('error');

  const currentSelectedProduct = localStorage.getItem('selectedProduct');
  const preset = localStorage.getItem('preset');
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId) {
      sendUserIdToAnalytics(userId);
    }
  }, [userId]);

  const retryHandler = () => {
    localStorage.setItem('retryPayment', uuid());
    localStorage.removeItem('paymentDecline');
  };

  useEffect(() => {
    handleAnalyticsEvents.handleDeclinePaymentAnalyticsEvents({
      selectedProduct: currentSelectedProduct,
      preset,
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('paymentDecline', '1');
  }, []);

  const isDesktop = useIsDesktop();

  return (
    <BaseLayout verticalAlignment={isDesktop && true}>
      <GridColumnContainer gridStep={24}>
        <Container margin={'40px 0px 0px 0px'}>
          <FormLabel>{t('BEST_TO_PAY:headers.paymentDeclined')}</FormLabel>
          <FormSubLabel>
            {t('BEST_TO_PAY:hints.technicalReasonsPaymentDeclined')}
          </FormSubLabel>
          <FormSub>
            <Button
              onClick={retryHandler}
              variant="primary"
              label={t('COMMON:buttons.tryAgain') || ''}
            />
          </FormSub>
        </Container>
      </GridColumnContainer>
    </BaseLayout>
  );
};
