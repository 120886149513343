import { FC, memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@pulse-web-ui/button';
import { Info } from '@pulse-web-ui/icons';
import { SelectorCard } from '@pulse-web-ui/selector-card';

import {
  AdaptiveListWrapper,
  Container,
  LinkContainer,
  RiskWrapper,
} from '@src/components';
import {
  FormHead,
  FormSubhead,
} from '@src/components/form-elements/form-elements';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents } from '@src/constants';
import { useHandlePressKey, useNextStep } from '@src/hooks';
import { Store } from '@src/store';
import { NSActionTypes } from '@src/store/ns';
import { WizardActionTypes } from '@src/store/wizard';
import { KeyCode, Risk } from '@src/types';

import { useNsDraft } from './hooks';

export const FormInsuranceAdditionalRisks: FC = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    state: {
      stateFormNS: { risks, selectedAdditionalRisks },
    },
    dispatch,
  } = useContext(Store);

  const additionalRisks = useMemo(
    () => risks?.filter((elem: Risk) => !elem.binding),
    [risks]
  );

  const storeSelectedAdditionalRisks = useCallback((data: string[]) => {
    dispatch({
      type: NSActionTypes.SetSelectedAdditionalRisks,
      payload: data,
    });
  }, []);

  const setStepUpdated = () => {
    dispatch({
      type: WizardActionTypes.SetUpdateFormState,
      payload: true,
    });
  };

  const handleRiskClick = (risk: Risk) => {
    const { code, name } = risk;

    setStepUpdated();

    const newSelectedRisks = selectedAdditionalRisks
      ? [...selectedAdditionalRisks]
      : [];

    if (!newSelectedRisks.includes(code)) {
      newSelectedRisks.push(code);
      sendAnalyticEvent(analyticEvents.selectProtectionAccident, {
        option: name,
      });
    } else {
      newSelectedRisks.splice(newSelectedRisks.indexOf(code), 1);
      sendAnalyticEvent(analyticEvents.deselectProtectionAccident, {
        option: name,
      });
    }

    storeSelectedAdditionalRisks(newSelectedRisks);
  };

  const descriptionNavHandler = () => {
    dispatch({
      type: NSActionTypes.SetRisksPage,
      payload: 'secondary',
    });

    navigate('/ns-risk-descriptions');
  };

  useNextStep(() => true);
  useNsDraft();

  const handleKeyPressEnter = () => {
    dispatch({
      type: WizardActionTypes.UpdateWantNextStep,
      payload: true,
    });
  };
  useHandlePressKey(KeyCode.ENTER, handleKeyPressEnter, []);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });
  }, []);

  useEffect(() => {
    if (risks && !selectedAdditionalRisks) {
      storeSelectedAdditionalRisks(
        risks
          .filter((elem: Risk) => !elem.binding && elem.active)
          .map((elem: Risk) => elem.code)
      );
    }
  }, [risks]);

  return (
    <Container>
      <FormHead>{t('NS_FORM:headers.additionalProtection')}</FormHead>
      <FormSubhead>{t('NS_FORM:hints.selectOptions')}</FormSubhead>
      <AdaptiveListWrapper>
        {(!additionalRisks || additionalRisks?.length === 0) &&
          t('COMMON:errors.noData')}
        {additionalRisks?.map((item) => (
          <RiskWrapper>
            <SelectorCard
              key={item.code}
              id={item.code}
              name={item.code}
              label={item.name}
              description={item.description}
              checked={!!selectedAdditionalRisks?.includes(item.code)}
              readOnly={!item.switchability}
              onClick={() => handleRiskClick(item)}
            />
          </RiskWrapper>
        ))}
      </AdaptiveListWrapper>
      <LinkContainer>
        <Button
          icon={<Info width={24} color="active" />}
          variant="text"
          onClick={descriptionNavHandler}
          label={t('COMMON:labels.aboutAdditionalRisks') || ''}
        />
      </LinkContainer>
    </Container>
  );
});
