import { css, mixins, styled } from '@pulse-web-ui/theme';

import { OrderSubTitle } from '@src/components';

export const ClientOrderPageLayout = styled.div`
  ${mixins.flex({ horizontal: false })};
  min-height: 100vh;
`;

export const ClientOrderPageWrapper = styled.div<{ error: boolean }>`
  ${mixins.flex({ justifyContent: 'center' })};
  width: 100%;
  box-sizing: border-box;
  padding: ${({ error }) => (error ? '16px 0 0 0' : '16px 16px 0')};
  background: ${({ theme, error }) =>
    error ? theme.colors.surface.background : theme.colors.surface.primary};

  ${({ error }) =>
    error &&
    css`
      flex: auto;
    `}
`;

export const StyledOrderSubTitle = styled(OrderSubTitle)`
  color: ${({ theme }) => theme.colors.text.error};
`;
