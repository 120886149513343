import { useContext, useEffect } from 'react';

import { OrderActionTypes, Store } from '@src/store';
import { AddressDataType, OrderRequestData } from '@src/types';
import {
  checkValidDate,
  getAddress,
  prepareDate,
  subObjectConverter,
} from '@src/utils';

export const useIflOrderFlatData = () => {
  const {
    state: {
      stateFormIFLFlat: {
        selectedIProduct,
        dadaValue,
        insuranceSubproducts,
        selectedDate,
        promoCode,
        insuranceSum,
        selectedDuration,
        risks,
        getPrices,
      },
      stateUser: { agentLogin },
    },
    dispatch,
  } = useContext(Store);

  const addressData = getAddress(dadaValue);

  const orderData: OrderRequestData = {
    productCode: selectedIProduct?.code,
    ...(agentLogin && { agentLogin }),
    contractDuration: selectedDuration,
    risks: risks
      ?.filter((item) => item.active === true)
      .map((item) => item.code),
    effectiveSince: prepareDate(
      checkValidDate(selectedDate) ? selectedDate : null
    ),
    insuranceSum: String(insuranceSum),
    personProperties: {
      subObjects:
        insuranceSubproducts?.subObjects &&
        subObjectConverter(insuranceSubproducts.subObjects),
      address: addressData,
    },
    ...(promoCode &&
      getPrices?.isSuccessfulPromo !== false && { promoCode: promoCode }),
  };

  useEffect(() => {
    dispatch({
      type: OrderActionTypes.SetOrderRequestData,
      payload: orderData,
    });
  }, [
    selectedIProduct,
    dadaValue,
    insuranceSubproducts,
    selectedDate,
    promoCode,
    insuranceSum,
    selectedDuration,
    risks,
    getPrices?.isSuccessfulPromo,
  ]);

  return orderData;
};
