/* eslint-disable indent */
import { format } from 'date-fns';
import { useContext } from 'react';

import { ObjectType, ProductKind } from '@src/constants';
import { Store } from '@src/store';
import {
  InsuranceObject,
  InsurePerson,
  RiskType,
  SumbitDataMite,
} from '@src/types';

const getRisks = (risks: Array<RiskType> | undefined) => {
  return risks?.map(({ code }) => code);
};

const formattingDate = (date: string | undefined) => {
  if (!date) {
    return '';
  }

  if (/\d{4}-\d{2}-\d{2}/.test(date)) {
    return date;
  }

  return format(new Date(date), 'yyyy-MM-dd');
};

const getInsuranceObjects = (persons: InsurePerson[]): InsuranceObject[] =>
  persons.map(
    (person: InsurePerson): InsuranceObject => ({
      objectType: ObjectType.PERSON,
      isInsurer: person.isMe,
      birthDate: formattingDate(person.birthDate),
      firstName: person.firstName,
      lastName: person.lastName,
      middleName: person.middleName,
      primaryRecordId: person.primaryRecordId,
    })
  );

export const userMiteOrderData = () => {
  const {
    state: {
      stateMite,
      stateUser: { agentLogin },
    },
  } = useContext(Store);

  const selectedPolicy =
    stateMite?.insuranceProductPolicy?.antimitePrograms?.find(
      (product) => product.insuranceProgram === stateMite.choosedPolicy
    );

  const orderData: SumbitDataMite = {
    baseParameters: {
      effectiveSince: selectedPolicy?.startDate || '',
      productCode: stateMite.insuranceProductPolicy?.productCode || '',
      ...(agentLogin && { agentLogin }),
      contractDuration: selectedPolicy?.insurancePeriod || '',
      risks: getRisks(selectedPolicy?.risks) || [],
      ...(stateMite.promoCode &&
        stateMite.insurancePrice?.isSuccessfulPromo !== false && {
          promoCode: stateMite.promoCode,
        }),
    },
    productProperty: {
      kind: ProductKind.MITE,
      program: stateMite.choosedPolicy || '',
      insuranceObjects: getInsuranceObjects(stateMite.insuredPersons),
    },
  };

  return {
    orderData,
  };
};
