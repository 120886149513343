/* eslint-disable indent */
import { merge } from 'lodash';
import { memo, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import { Spinner } from '@pulse-web-ui/spinner';
import {
  StyledThemeProvider,
  baseTheme,
  createGlobalStyle,
} from '@pulse-web-ui/theme';
import type { BaseThemeType } from '@pulse-web-ui/theme';

import { PageSpinnerWrapper } from '@src/components';
import { useSetPresetDataInStore, useThemeRequest } from '@src/hooks';
import { AuthFlow, ThemeConfig } from '@src/types';

import { useDictionary, useFontLink, useThemeAuthFlowRequest } from './hooks';
import { FontContainer } from './theme-container.styles';
import { getThemeLink, getThemeURL } from './utils';

type Props = {
  themeUrl?: string;
};

type GlobalThemeProps = {
  theme: BaseThemeType;
};

export const ThemeContainerIn: FC<Props> = memo(({ children, themeUrl }) => {
  // themeUrl - составной параметр вида “partnername_customname”
  // первая часть partnername - название партнера (соответствующий каталог в CDN)
  // вторая часть customname - название конфига кастомизации (внутри на первом этапе два параметра с названием темы ui и названием коллекции текстов texts)
  // подробнее - в документации

  const [partnername, customname] = themeUrl?.split('_') || [];
  const themeLink = getThemeLink(partnername, customname);
  const [isLoading, setIsLoading] = useState(!!themeUrl);

  const fontLink = partnername
    ? `${window.envUrls.STORAGE_URL}/themes/${String(partnername)}/fonts`
    : '';

  const { isLoading: isPresetDataLoading } = useSetPresetDataInStore();

  const { isLoading: isLoadingConfig, res: resConfig } =
    useThemeRequest<ThemeConfig>(
      'themingRequest',
      themeLink,
      [themeLink],
      !!themeLink
    );

  const { isLoading: isLoadingTheme, res: resTheme } =
    useThemeRequest<ThemeConfig>(
      'theme',
      getThemeURL(resConfig, partnername, 'ui', 'theme-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'ui', 'theme-url')
    );

  const { isLoading: isLoadingTexts, res: resTexts } =
    useThemeRequest<ThemeConfig>(
      'texts',
      getThemeURL(resConfig, partnername, 'i18n', 'texts-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'i18n', 'texts-url')
    );

  const { isLoading: isLoadingAuthFlow } = useThemeAuthFlowRequest(
    resConfig,
    partnername
  );

  const { isLoading: isLoadingIcons, res: resIcons } =
    useThemeRequest<AuthFlow>(
      'icons',
      getThemeURL(resConfig, partnername, 'icons', 'icons-url'),
      [resConfig, partnername],
      !!getThemeURL(resConfig, partnername, 'icons', 'icons-url')
    );

  const customTheme = useMemo(() => {
    if (!isLoading && resTheme) {
      return merge(baseTheme, resTheme, resIcons || {});
    }

    return baseTheme;
  }, [isLoading, resTheme, resIcons]);

  useFontLink(fontLink, customTheme);

  const GlobalStyle = createGlobalStyle`
    body {
      ${({ theme }: GlobalThemeProps) => {
        if (theme.common['font-url'])
          return `
          * {
            font-family: ${theme.common['font-url']}
          }`;
        else if (theme.common['font-family']) {
          return `
          * {
            font-family: ${theme.common['font-family']}
          }`;
        }
        return '';
      }};
    };
  `;

  useDictionary(resTexts, isLoadingTexts);

  useEffect(() => {
    setIsLoading(
      isLoadingConfig ||
        isLoadingTheme ||
        isLoadingTexts ||
        isLoadingAuthFlow ||
        isLoadingIcons ||
        isPresetDataLoading
    );
  }, [
    isLoadingConfig,
    isLoadingTheme,
    isLoadingTexts,
    isLoadingAuthFlow,
    isLoadingIcons,
    isPresetDataLoading,
  ]);

  if (isLoading) {
    return (
      <StyledThemeProvider theme={baseTheme}>
        <PageSpinnerWrapper>
          <Spinner />
        </PageSpinnerWrapper>
      </StyledThemeProvider>
    );
  }

  return (
    <StyledThemeProvider theme={customTheme}>
      <GlobalStyle />
      <FontContainer>{children}</FontContainer>
    </StyledThemeProvider>
  );
});
