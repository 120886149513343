/* eslint-disable indent */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  AnalyticsType,
  sendAnalyticEvent,
  sendUserIdToAnalytics,
} from '@src/components/web-analytic';
import {
  ClientCategory,
  Product,
  adriverEvents,
  analyticEvents,
  salesNinjaEvents,
} from '@src/constants';
import { AuthActionTypes, Store } from '@src/store';
import type { RegistrationResponse } from '@src/types';
import { handleAnalyticsEvents } from '@src/utils';

import { useRequest } from './use-request';

export function useRegistrationRequest<T>(deps: T[] = []) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    state: {
      stateAuth: { authTokens },
      stateUser: {
        userRegistrationData,
        preset,
        selectedProduct,
        clientCategoryFromUrl,
        profile,
        cid,
      },
    },
    dispatch,
  } = useContext(Store);
  const isNeedToRegisterAsVip =
    selectedProduct === Product.HOUSE &&
    clientCategoryFromUrl === ClientCategory.VIP &&
    preset;

  const {
    isLoading: isRegistrationLoading,
    error: registrationError,
    res: registrationRes,
    refetch: registrationRefetch,
  } = useRequest<RegistrationResponse>(
    'registrationRequest',
    'post',
    '/v1/user/registration',
    {
      user: {
        ...userRegistrationData,
        ...(isNeedToRegisterAsVip && { clientCategory: ClientCategory.VIP }),
      },
    },
    deps,
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (registrationRes && cid && profile?.profile.userId) {
      const includedProducts = [
        Product.PETS,
        Product.APARTMENT,
        Product.SPORT,
        Product.NS,
      ];

      if (includedProducts.includes(selectedProduct as Product)) {
        sendAnalyticEvent(
          analyticEvents.cpaScaleoConvStatus,
          { cid, adv_order_id: profile?.profile.userId },
          AnalyticsType.cpaScaleo
        );
      }
    }
  }, [registrationRes]);

  useEffect(() => {
    if (
      registrationError &&
      registrationError?.code !== 'ERR_NETWORK' &&
      registrationError?.response?.data?.code !== 'TECHNICAL_ERROR'
    ) {
      const e = registrationError?.response?.status;

      if (e === 401) {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: t('COMMON:errors.somethingWentWrong'),
            subtitle: t('COMMON:errors.retryRegistration'),
            refRoute: '/login',
          },
        });
      } else {
        dispatch({
          type: AuthActionTypes.SetAuthorizeFailState,
          payload: {
            title: t('COMMON:errors.sendingError'),
            subtitle: t('COMMON:errors.retryRegistrationOrWriteToSupport'),
            refRoute: '/login',
          },
        });
      }

      navigate('/authorize-fail');
    }
  }, [registrationError]);

  useEffect(() => {
    if (!isRegistrationLoading && registrationRes) {
      if (registrationRes?.authorization?.csrfToken) {
        localStorage.setItem(
          'csrfToken',
          registrationRes?.authorization?.csrfToken
        );
      }
      dispatch({
        type: AuthActionTypes.SetAuthTokens,
        payload: registrationRes,
      });

      const userId = profile?.profile?.userId;

      if (userId) {
        sendUserIdToAnalytics(userId);
        localStorage.setItem('userId', userId);
      }

      let myTrackerLogin = analyticEvents.completeLogin;

      switch (selectedProduct) {
        case Product.SPORT:
          myTrackerLogin = analyticEvents.sportCompleteLogin;
          sendAnalyticEvent(
            analyticEvents.sportSignupCompleted,
            undefined,
            AnalyticsType.adriver
          );
          sendAnalyticEvent(
            salesNinjaEvents.authSportSignupCompleted,
            null,
            AnalyticsType.salesNinja
          );
          sendAnalyticEvent(
            adriverEvents.authSportRegistrationCompleted,
            null,
            AnalyticsType.adriver
          );
          break;
        case Product.APARTMENT:
          myTrackerLogin = analyticEvents.flatCompleteLogin;
          sendAnalyticEvent(
            analyticEvents.flatSignupCompleted,
            undefined,
            AnalyticsType.adriver
          );
          sendAnalyticEvent(
            salesNinjaEvents.authFlatSignupCompleted,
            null,
            AnalyticsType.salesNinja
          );
          sendAnalyticEvent(
            adriverEvents.authFlatRegistrationCompleted,
            null,
            AnalyticsType.adriver
          );
          break;
        case Product.HOUSE:
          sendAnalyticEvent(
            salesNinjaEvents.authHouseSignupCompleted,
            null,
            AnalyticsType.salesNinja
          );
          sendAnalyticEvent(
            adriverEvents.authHouseRegistrationCompleted,
            null,
            AnalyticsType.adriver
          );
          break;
        case Product.NS:
          sendAnalyticEvent(
            salesNinjaEvents.authNSSignupCompleted,
            null,
            AnalyticsType.salesNinja
          );
          sendAnalyticEvent(
            adriverEvents.authNSRegistrationCompleted,
            null,
            AnalyticsType.adriver
          );
          break;
        case Product.MITE:
          myTrackerLogin = analyticEvents.antimiteCompleteLogin;
          sendAnalyticEvent(
            analyticEvents.miteSignupCompleted,
            undefined,
            AnalyticsType.adriver
          );
          sendAnalyticEvent(
            salesNinjaEvents.authMiteSignupCompleted,
            null,
            AnalyticsType.salesNinja
          );
          sendAnalyticEvent(
            adriverEvents.authMiteRegistrationCompleted,
            null,
            AnalyticsType.adriver
          );
          break;
        case Product.PETS:
          myTrackerLogin = analyticEvents.petCompleteLogin;
          sendAnalyticEvent(
            analyticEvents.petsSignupCompleted,
            undefined,
            AnalyticsType.adriver
          );
          sendAnalyticEvent(
            salesNinjaEvents.authPetSignupCompleted,
            null,
            AnalyticsType.salesNinja
          );
          sendAnalyticEvent(
            adriverEvents.authPetRegistrationCompleted,
            null,
            AnalyticsType.adriver
          );
          break;
      }

      sendAnalyticEvent(myTrackerLogin, {}, AnalyticsType.myTracker);

      !!selectedProduct &&
        handleAnalyticsEvents.handleRegistrationComplete(
          selectedProduct,
          !!preset
        );

      if (preset) {
        sendAnalyticEvent(analyticEvents.signupPresetCompleted);
      } else {
        if (registrationRes?.isVip) {
          localStorage.setItem('clientCategory', ClientCategory.VIP);
          sendAnalyticEvent(analyticEvents.signupCompleted, {
            registration: 'y',
            VIP: 'y',
          });
        } else {
          localStorage.setItem('clientCategory', ClientCategory.STANDART);
          sendAnalyticEvent(analyticEvents.signupCompleted, {
            registration: 'y',
          });
        }
      }
    }
  }, [isRegistrationLoading, registrationRes]);

  return {
    isRegistrationLoading,
    registrationError,
    registrationRes,
    registrationRefetch,
  };
}
