export enum IFLHouseActionTypes {
  SetSelectedIProduct = 'IFL_HOUSE/SET_SELECTED_I_PRODUCT',
  SetSelectedRegion = 'IFL_HOUSE/SET_SELECTED_REGION',
  SetRegions = 'IFL_HOUSE/SET_REGIONS',
  SetRisks = 'IFL_HOUSE/SET_RISKS',
  SetInsuranceSubproducts = 'IFL_HOUSE/SET_INSURANCE_SUBPRODUCTS',
  SetInsuranceSum = 'IFL_HOUSE/SET_INSURANCE_SUM',
  SetGetPrices = 'IFL_HOUSE/SET_GET_PRICES',
  SetGetPricesFull = 'IFL_HOUSE/SET_GET_PRICES_FULL',
  SetSelectedDuration = 'IFL_HOUSE/SET_SELECTED_DURATION',
  SetSelectedDate = 'IFL_HOUSE/SET_SELECTED_DATE',
  SetDadaValue = 'IFL_HOUSE/SET_DADA_VALUE',
  SetPromoCode = 'IFL_HOUSE/SET_PROMO_CODE',
  SetPromoCodeApplyed = 'IFL_HOUSE/SET_PROMO_CODE_APPLYED',
  SetRisksPage = 'IFL_HOUSE/SET_RISKS_PAGE',
  SetMaterials = 'IFL_HOUSE/SET_MATERIALS',
  SetSelectedBuildingMaterialCode = 'IFL_HOUSE/SET_SELECTED_BUILDING_MATERIAL_CODE',
  SetFieldWithEmptyDependencies = 'IFL_HOUSE/SET_FIELD_WITH_EMPTY_DEPENDENCIES',
  SetEmptyDependencies = 'IFL_HOUSE/SET_EMPTY_DEPENDENCIES',
  SetIsObjectDataError = 'IFL_HOUSE/SET_IS_OBJECT_DATA_ERROR',
  SetGetSubobjectsRefetchUid = 'IFL_HOUSE/SET_GET_SUB_OBJECTS_REFETCH_UID',
  SetCurrentSum = 'IFL_HOUSE/SET_CURRENT_SUM',
  SetCurrentSumMinLimit = 'IFL_HOUSE/SET_CURRENT_SUM_MIN_LIMIT',
  SetCurrentSumMaxLimit = 'IFL_HOUSE/SET_CURRENT_SUM_MAX_LIMIT',
  SetCurrentSumErr = 'IFL_HOUSE/SET_CURRENT_SUM_ERR',
  SetSelectedId = 'IFL_HOUSE/SET_SELECTED_ID',
  SetIsSelectedCustomStreet = 'IFL_HOUSE/SET_IS_SELECTED_CUSTOM_STREET',
  SetIsSelectedCustomHouse = 'IFL_HOUSE/SET_IS_SELECTED_CUSTOM_HOUSE',
  SetIsEmptyStreetSuggestions = 'IFL_HOUSE/SET_IS_EMPTY_STREET_SUGGESTIONS',
  SetIsEmptyHouseSuggestions = 'IFL_HOUSE/SET_IS_EMPTY_HOUSE_SUGGESTIONS',
  SetPresetData = 'IFL_HOUSE/SET_PRESET_DATA',
  UpdateState = 'IFL_HOUSE/UPDATE_STATE',
  SetIsSpecialTermsInsurance = 'IFL_HOUSE/SET_IS_SPECIAL_TERMS_INSURANCE',
}

export interface SubObject {
  code: string;
  defaultInsuranceSum: string;
}

export interface HousePresetData {
  buildingMaterial: string;
  risks: Array<string>;
  subObjects: Array<SubObject>;
}
