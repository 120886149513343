import { useContext, useEffect } from 'react';

import { Store } from '@src/store';
import { setDraftLS } from '@src/utils';

export const useSportDraft = () => {
  const {
    state: {
      stateFormNSSport: {
        insuranceProduct,
        insuranceProductDate,
        selectedStartDate,
        selectedEndDate,
        numberInsurePersons,
        promoCode,
        promoCodeApplyed,
        sportKinds,
        choosedSportKinds,
        risks,
        sublimit,
        insuranceSum,
        insurancePrice,
        insurePersons,
        selectedDuration,
      },
      stateFormNSSport,
      stateUser: { selectedProduct, agentLogin },
      stateWizard: { currentStep },
    },
  } = useContext(Store);

  useEffect(() => {
    if (!agentLogin) {
      const data = {
        dataObject: {
          ...stateFormNSSport,
          promoCode: promoCodeApplyed && promoCode,
        },
        selectedProduct,
        currentStep: currentStep || 0,
      };

      setDraftLS(data);
    }
  }, [
    insuranceProduct,
    insuranceProductDate,
    selectedStartDate,
    selectedEndDate,
    numberInsurePersons,
    promoCode,
    sportKinds,
    choosedSportKinds,
    risks,
    sublimit,
    insuranceSum,
    insurancePrice,
    insurePersons,
    selectedDuration,
    agentLogin,
  ]);

  return;
};
