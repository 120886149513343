import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutocompleteSelect } from '@pulse-web-ui/autocomplete-select';
import { HelperText } from '@pulse-web-ui/helper-text';
import { Input } from '@pulse-web-ui/input';
import { Select } from '@pulse-web-ui/select';

import {
  AdaptiveColumns,
  AdaptiveContainerInverse,
  Container,
  ControllerContainer,
  FormLabel,
  Skeleton,
  UserAgreement,
} from '@src/components';
import { sendAnalyticEvent } from '@src/components/web-analytic/utils';
import { analyticEvents, insuranceProductsCode } from '@src/constants';
import { GlobalErrorInfo } from '@src/features';
import { useNextStep, useRequest } from '@src/hooks';
import {
  AuthActionTypes,
  PetsActionTypes,
  Store,
  WizardActionTypes,
} from '@src/store';
import { SelectedPetData } from '@src/store/pets';

import { usePetsDataSchema, usePetsDraft } from './hooks';
import {
  createKindOptions,
  getAgeOptionsByKind,
  getBreedOptionsByKind,
} from './utils';

type PetsDataForm = SelectedPetData;

export const PetsData = () => {
  const { t } = useTranslation();
  const {
    state: {
      stateFormPets: { pets, selectedPetData, presetData },
      stateUser: { agentLogin },
    },
    dispatch,
  } = useContext(Store);
  const [petKind, setPetKind] = useState(selectedPetData?.kind || '');
  const [formData, setFormData] = useState<PetsDataForm | undefined>();

  const petsDataSchema = usePetsDataSchema();

  const {
    control,
    formState: { errors },
    handleSubmit,
    unregister,
    getValues,
    setValue,
    reset,
    watch,
  } = useForm<PetsDataForm>({
    resolver: yupResolver(petsDataSchema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: formData,
  });

  useEffect(() => {
    const subscription = watch((value) => {
      setFormData(value as unknown as SelectedPetData);
      dispatch({
        type: PetsActionTypes.SetSelectedPetData,
        payload: value as unknown as SelectedPetData,
      });
      dispatch({
        type: PetsActionTypes.SetSelectedPetKindName,
        payload: pets.find(({ kind }) => kind === value.kind)?.kindName,
      });
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (!!presetData) {
      sendAnalyticEvent(analyticEvents.petToStepPetTypePreset);
    } else {
      sendAnalyticEvent(analyticEvents.petToStepPetType);
    }
  }, []);

  const kindOptions = useMemo(() => {
    const options = createKindOptions(pets);

    if (!!presetData?.petKinds) {
      return options.filter(({ value }) =>
        presetData?.petKinds?.includes(value)
      );
    }

    return options;
  }, [pets, presetData?.petKinds]);
  const hasPetBreeds = useMemo(
    () =>
      !!petKind.length &&
      !!pets.find((pet) => pet.kind === petKind)?.breeds?.length,
    [petKind, pets]
  );
  const onKindChange = (val: string) => {
    setPetKind(val);
    reset({
      kind: val,
    });

    dispatch({
      type: PetsActionTypes.SetRisks,
      payload: undefined,
    });
    dispatch({
      type: PetsActionTypes.SetCurrentSubLimits,
      payload: undefined,
    });
    dispatch({
      type: PetsActionTypes.SetSublimits,
      payload: undefined,
    });
  };

  useEffect(() => {
    if (!!presetData?.petKind) {
      onKindChange(presetData.petKind);
    }
  }, [presetData?.petKind]);

  const submitPage = handleSubmit((data) => {
    setFormData(data);
    dispatch({
      type: PetsActionTypes.SetSelectedPetData,
      payload: data,
    });
    dispatch({
      type: PetsActionTypes.SetSelectedPetKindName,
      payload: pets.find(({ kind }) => kind === data.kind)?.kindName,
    });
  });

  const validatePage = () =>
    submitPage().then(
      () =>
        !Object.values(getValues()).some((val) => !val) &&
        !Object.keys(errors).length
    );

  useNextStep(validatePage);
  usePetsDraft();

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: false,
    });

    dispatch({
      type: AuthActionTypes.SetAuthorizeRefRoute,
      payload: '/pets',
    });
  }, []);

  useEffect(() => {
    if (!hasPetBreeds) {
      unregister('breed');
    }
  }, [hasPetBreeds, unregister]);

  useEffect(() => {
    if (selectedPetData) {
      Object.keys(selectedPetData).forEach((key) => {
        const formFieldKey = key as keyof PetsDataForm;
        setValue(formFieldKey, selectedPetData[formFieldKey]);
      });
    }
  }, []);

  const {
    isLoading,
    error,
    res: petsInfoResponse,
    refetch,
  } = useRequest(
    'petsFormGetPetsInfo',
    'post',
    '/v1/references/get-pets-info',
    {
      productCode: insuranceProductsCode.pets,
    }
  );

  useEffect(() => {
    if (!isLoading && petsInfoResponse && !!petsInfoResponse?.pets?.length) {
      dispatch({
        type: PetsActionTypes.SetPets,
        payload: petsInfoResponse.pets,
      });
    }
  }, [isLoading, petsInfoResponse]);

  useEffect(() => {
    dispatch({
      type: WizardActionTypes.SetFwNavDisabled,
      payload: !!error,
    });
  }, [error]);

  if (isLoading) return <Skeleton />;

  if (error) {
    return <GlobalErrorInfo pending={isLoading} retrayHandler={refetch} />;
  }

  return (
    <AdaptiveContainerInverse>
      <Container margin={'0 0 24px 0!important'}>
        <FormLabel marginBottom={32}>
          {t('PETS_FORM_DATA:headers.informationAboutYourPet')}
        </FormLabel>
        <ControllerContainer margin="0 0 16px 0">
          <Controller
            control={control}
            name="kind"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={errors.kind?.message}
              >
                <Select
                  onChange={(val) => {
                    dispatch({
                      type: PetsActionTypes.SetSublimits,
                      payload: undefined,
                    });
                    onKindChange(val);
                    onChange(val);
                  }}
                  value={value || ''}
                  options={kindOptions}
                  placeholder={t('PETS_FORM_DATA:placeholders.type') || ''}
                  status={fieldState.error && 'error'}
                  disabled={!!presetData?.petKind}
                />
              </HelperText>
            )}
          />
        </ControllerContainer>
        {hasPetBreeds && (
          <ControllerContainer margin="0 0 16px 0">
            <Controller
              control={control}
              name="breed"
              render={({ field: { onChange, value }, fieldState }) => (
                <HelperText
                  status={fieldState.error ? 'error' : 'default'}
                  message={errors.breed?.message}
                >
                  <AutocompleteSelect
                    onChange={onChange}
                    arrowWidth={49}
                    value={value || ''}
                    options={getBreedOptionsByKind(pets, petKind)}
                    label={t('PETS_FORM_DATA:placeholders.breed') || ''}
                    status={fieldState.error && 'error'}
                  />
                </HelperText>
              )}
            />
          </ControllerContainer>
        )}
        <AdaptiveColumns>
          <Controller
            control={control}
            name="age"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={
                  errors.age?.message || t('PETS_FORM_DATA:hints.petAge')
                }
              >
                <Select
                  onChange={onChange}
                  value={value || ''}
                  options={
                    !!petKind.length ? getAgeOptionsByKind(pets, petKind) : []
                  }
                  placeholder={t('PETS_FORM_DATA:placeholders.age') || ''}
                  status={fieldState.error && 'error'}
                  disabled={!petKind.length}
                />
              </HelperText>
            )}
          />
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, value }, fieldState }) => (
              <HelperText
                status={fieldState.error ? 'error' : 'default'}
                message={errors.gender?.message}
              >
                <Select
                  onChange={onChange}
                  value={value || ''}
                  options={[
                    {
                      label: t('PETS_FORM_DATA:labels.male'),
                      value: t('PETS_FORM_DATA:labels.male'),
                    },
                    {
                      label: t('PETS_FORM_DATA:labels.female'),
                      value: t('PETS_FORM_DATA:labels.female'),
                    },
                  ]}
                  placeholder={t('PETS_FORM_DATA:placeholders.gender') || ''}
                  status={fieldState.error && 'error'}
                />
              </HelperText>
            )}
          />
        </AdaptiveColumns>
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, onBlur, value }, fieldState }) => (
            <HelperText
              status={fieldState.error ? 'error' : 'default'}
              message={
                errors.name?.message || t('PETS_FORM_DATA:hints.petName')
              }
            >
              <Input
                name="name"
                onChange={onChange}
                value={value || ''}
                label={t('PETS_FORM_DATA:labels.whatNameOfPet') || ''}
                error={!!errors.name}
                onBlur={onBlur}
              />
            </HelperText>
          )}
        />
      </Container>
      {!agentLogin && <UserAgreement />}
    </AdaptiveContainerInverse>
  );
};
