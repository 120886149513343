import { VFC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FeatureFlags } from '@src/constants';
import { useFeatureFlags } from '@src/hooks';
import type { Price } from '@src/types';
import { getDiscount } from '@src/utils';

type Props = {
  prices: Price[];
  featureFlag: FeatureFlags;
};

export const PromoCodeDiscount: VFC<Props> = ({ prices, featureFlag }) => {
  const { t } = useTranslation();
  const {
    res: [isFeatureDiscount],
  } = useFeatureFlags([featureFlag]);

  const discountPrice = prices.find((price) => price?.premiumAndDeltaPromo);

  const promoDiscount = useMemo(
    () =>
      discountPrice &&
      discountPrice.premiumAndDeltaPromo &&
      getDiscount(
        discountPrice.premiumAndDelta,
        discountPrice.premiumAndDeltaPromo
      ),
    [prices]
  );

  if (!discountPrice) return null;

  return (
    <Trans
      values={{
        promoDiscount,
      }}
    >
      {isFeatureDiscount
        ? t('COMMON:hints.discountForFirstPeriodFeature')
        : t('COMMON:hints.discountForFirstPeriod')}
    </Trans>
  );
};
